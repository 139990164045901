import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { getRole } from "@/api/auth";
import { getLearning, getLearningRef } from "@/api/learning";
import { pageViewLogEvent, setUserIdToAnalytics } from "@/api/analytics";
import { saveErrorLog } from "@/api/error";

let authUnsubscribe: firebase.Unsubscribe | null = null;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: { requireAuth: true },
    redirect: "/home"
  },
  {
    path: "/home",
    name: "userhome",
    component: () => import("../views/Home.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/apply",
    name: "apply",
    component: () => import("../views/Apply.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/unavailable",
    name: "unavailable",
    component: () => import("../views/Unavailable.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/setting_password",
    name: "setting_password",
    component: () => import("../views/SettingPassword.vue")
  },
  {
    path: "/student",
    name: "student_list",
    component: () => import("../views/StudentList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/group",
    name: "group_list",
    component: () => import("../views/GroupList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/message",
    name: "message_list",
    component: () => import("../views/MessageList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/qanda/:studentId",
    name: "qanda_question_list",
    component: () => import("../views/QandaQuestionList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/qanda/:studentId/:type/:historyId/question",
    name: "qanda_question",
    component: () => import("../views/QandaQuestion.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/student/print/auth",
    name: "print_auth",
    component: () => import("../views/PrintInvitation.vue"),
    beforeEnter(_, __, next) {
      if (store.state.selectedLoginInfos.length === 0) {
        next("/");
        return;
      }
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/print/school_ai",
    name: "print_school_ai",
    component: () => import("../views/PrintInvitationSchoolAi.vue"),
    beforeEnter(_, __, next) {
      if (store.state.selectedLoginInfos.length === 0) {
        next("/");
        return;
      }
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/:schoolId/:classroomId/:studentId/profile",
    name: "student_profile",
    component: () => import("../views/Student.vue"),
    beforeEnter(_, __, next) {
      store.commit("student/SET_PAGE_TYPE", "profile");
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/:schoolId/:classroomId/:studentId/history",
    name: "student_history",
    component: () => import("../views/Student.vue"),
    beforeEnter(_, __, next) {
      store.commit("student/SET_PAGE_TYPE", "history");
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/:schoolId/:classroomId/:studentId/history/:learningId",
    name: "student_learning",
    component: () => import("../views/Student.vue"),
    beforeEnter(to, from, next) {
      store.commit("student/SET_PAGE_TYPE", "learning");
      const schoolId = to.params.schoolId as string;
      const classroomId = to.params.classroomId as string;
      const studentId = to.params.studentId as string;
      const learningId = to.params.learningId as string;
      getLearning(getLearningRef(schoolId, classroomId, studentId, learningId))
        .then(res => {
          if (!res) {
            alert("学習履歴が見つかりませんでした");
            return;
          }
          store.commit("student/SET_LEARNING", res);
        })
        .catch(e => {
          alert(`学習履歴が見つかりませんでした\n\n${e}`);
          saveErrorLog(
            store.state.role,
            e.code,
            e.message,
            "Failed to get learning"
          );
        });
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/:schoolId/:classroomId/:studentId/badge",
    name: "student_badge",
    component: () => import("../views/Student.vue"),
    beforeEnter(_, __, next) {
      store.commit("student/SET_PAGE_TYPE", "badge");
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/:schoolId/:classroomId/:studentId/submissions",
    name: "student_submissions",
    component: () => import("../views/Student.vue"),
    beforeEnter(_, __, next) {
      store.commit("student/SET_PAGE_TYPE", "submissions");
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/student/:schoolId/:classroomId/:studentId/todo",
    name: "student_todo",
    component: () => import("../views/Student.vue"),
    beforeEnter(_, __, next) {
      store.commit("student/SET_PAGE_TYPE", "todo");
      next();
    },
    meta: { requireAuth: true }
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("../views/Notifications.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/staff",
    name: "staff_list",
    component: () => import("../views/StaffList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/classroom",
    name: "classroom_list",
    component: () => import("../views/ClassroomList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/room",
    name: "room_setting",
    component: () => import("../views/RoomSettings.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/room/future",
    name: "room_reservation_list",
    component: () => import("../views/RoomReservationList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/room/student",
    name: "room_student_list",
    component: () => import("../views/RoomStudentList.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/school",
    name: "school_setting",
    component: () => import("../views/SchoolSettings.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/mini_app",
    name: "mini_app_setting",
    component: () => import("../views/MiniAppSettings.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/submission_master",
    name: "submission_master_list",
    component: () => import("../views/SubmissionMasterList.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/submission_master/:submissionMasterId",
    name: "submission_master_detail",
    component: () => import("../views/SubmissionMasterDetail.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/submission/print",
    name: "submission_print",
    component: () => import("../views/SubmissionPrint.vue"),
    meta: { requireAuth: true }
  },
  {
    path: "/thread_master/:threadMasterId",
    name: "thread_master_detail",
    component: () => import("../views/ThreadMasterDetail.vue"),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/thread/print",
    name: "thread_print",
    component: () => import("../views/ThreadPrint.vue"),
    meta: { requireAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, _, next) => {
  document.title = "みんがく管理アプリ";
  pageViewLogEvent(to.fullPath, to.path, to.name as string);

  const requireAuth = to.matched.some(record => record.meta.requireAuth);
  const requireAdmin = to.matched.some(record => record.meta.requireAdmin);

  if (authUnsubscribe !== null) {
    authUnsubscribe();
  }
  authUnsubscribe = firebase.auth().onAuthStateChanged(async function(user) {
    if (!user && !requireAuth) {
      next();
      return;
    } else if (!user) {
      next("/login");
      return;
    }

    setUserIdToAnalytics(user.uid);

    // ユーザー関連情報の取得
    if (!store.state.role) {
      try {
        const role = await getRole(user.uid);
        if (!role) {
          alert("ユーザー情報の取得に失敗しました");
          next("/login");
          return;
        }
        store.commit("SET_ROLE", role);
        await store.dispatch("getUserInfo", role);
      } catch (e) {
        alert(`ユーザー情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(null, e.code, e.message, "Failed to get role data");
        next("/login");
        return;
      }
    }

    // apply page を開くために必要な権限の確認
    if (to.name === "apply") {
      const tutor = store.state.tutor;
      const schoolConfig = store.state.schoolConfig;
      if (
        !tutor || // tutor がない場合は apply page は開けない
        !schoolConfig || // schoolConfig がない場合は apply page は開けない
        tutor.config.data.authority !== "owner" || // school の owner でない場合は apply page は開けない
        (schoolConfig.data.activateTime && schoolConfig.data.activateTime > 0) // school の activateTime が設定されている場合は apply page は開けない
      ) {
        next("/");
        return;
      }
    }

    // 塾が無効化されている場合は unavailable page しか開けない, 逆に無効化されていない場合には unavailable page は開けない
    if (to.name === "unavailable") {
      if (!store.state.school || !store.state.school.data.suspended) {
        next("/");
        return;
      } else {
        next();
        return;
      }
    } else if (store.state.school && store.state.school.data.suspended) {
      next("/unavailable");
      return;
    }

    // 管理人権限が必要なページは管理人しか開けない
    if (
      requireAdmin &&
      (!store.state.role || store.state.role.data.type !== "admin")
    ) {
      next("/");
      return;
    }

    next();
  });
});

export default router;
