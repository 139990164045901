
import { Options, Vue } from "vue-class-component";
import MLoadingOverlay from "@/components/MLoadingOverlay.vue";
import MUserModal from "@/components/MUserModal.vue";
import MsAddMessageTemplateModal from "@/components/student/MsAddMessageTemplateModal.vue";
import MsEditMessageTemplateModal from "@/components/student/MsEditMessageTemplateModal.vue";
import store from "./store";
import { MessageTemplate } from "./entities/message_template";
import { State } from "./store/helper";

@Options({
  components: {
    MLoadingOverlay,
    MUserModal,
    MsAddMessageTemplateModal,
    MsEditMessageTemplateModal
  }
})
export default class App extends Vue {
  @State("loading") loading!: boolean;
  @State("isOpenUserModal") isOpenUserModal!: boolean;
  @State("isOpenAddMessageTemplateModal", "messageTemplateSheet")
  isOpenAddMessageTemplateModal!: boolean;
  @State("isOpenEditMessageTemplateModal", "messageTemplateSheet")
  isOpenEditMessageTemplateModal!: boolean;
  @State("selectedMessageTemplate", "messageTemplateSheet")
  selectedMessageTemplate!: MessageTemplate | null;
  @State("defaultMessageContent", "messageTemplateSheet")
  defaultMessageContent!: string;

  closeUserModal() {
    store.commit("SET_IS_OPEN_USER_MODAL", false);
  }

  closeAddMessageTemplateModal() {
    store.commit(
      "messageTemplateSheet/SET_IS_OPEN_ADD_MESSAGE_TEMPLATE_MODAL",
      false
    );
  }

  closeEditMessageTemplateModal() {
    store.commit(
      "messageTemplateSheet/SET_IS_OPEN_EDIT_MESSAGE_TEMPLATE_MODAL",
      false
    );
  }
}
