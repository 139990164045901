import {
  convertToSchool,
  convertToSchoolConfig,
  Role,
  School,
  schoolCollectionKey,
  SchoolConfig,
  schoolConfigCollectionKey
} from "@/entities/school";
import { labelCollectionKey, LabelData } from "@/entities/label";
import { Student, StudentGrade } from "@/entities/student";
import { TutorAuthority } from "@/entities/tutor";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export async function getSchool(
  ref: firebase.firestore.DocumentReference
): Promise<School | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }

  return convertToSchool(data, snapshot.ref);
}

export async function getSchoolConfig(
  ref: firebase.firestore.DocumentReference
): Promise<SchoolConfig | null> {
  const snapshot = await ref.get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }

  return convertToSchoolConfig(data, snapshot.ref);
}

export async function getSchoolDetail(
  isSchoolAdmin: boolean,
  schoolId: string
): Promise<[School | null, SchoolConfig | null]> {
  const db = firebase.firestore();
  if (isSchoolAdmin) {
    return await Promise.all([
      getSchool(db.collection(schoolCollectionKey).doc(schoolId)),
      getSchoolConfig(db.collection(schoolConfigCollectionKey).doc(schoolId))
    ]);
  } else {
    const school = await getSchool(
      db.collection(schoolCollectionKey).doc(schoolId)
    );
    return [school, null];
  }
}

export async function getSchools(): Promise<School[]> {
  const snapshot = await firebase
    .firestore()
    .collection(schoolCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToSchool(doc.data(), doc.ref));
}

export async function getSchoolsForServiceProvider(
  schoolId: string
): Promise<School[]> {
  const snapshots = [];

  const schoolConfigSnapshot = await firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(schoolId)
    .get();

  if (!schoolConfigSnapshot) {
    return [];
  }

  const childrenSchoolDocIds = schoolConfigSnapshot.data()
    ?.childrenSchoolDocIds;

  if (!childrenSchoolDocIds) {
    return [];
  }

  const childrenSchooolsSnapshotsPromises = childrenSchoolDocIds.map(
    (schoolDocId: string) => {
      return firebase
        .firestore()
        .collection(schoolCollectionKey)
        .doc(schoolDocId)
        .get();
    }
  );

  const results: firebase.firestore.DocumentSnapshot[] = await Promise.all(
    childrenSchooolsSnapshotsPromises
  );

  snapshots.push(...results);

  return snapshots
    .map(doc => {
      const data = doc.data();
      if (data) {
        return convertToSchool(data, doc.ref);
      }
      return null;
    })
    .filter(school => school !== null) as School[];
}

export async function fetchSchoolConfigs(): Promise<SchoolConfig[]> {
  const snapshot = await firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToSchoolConfig(doc.data(), doc.ref));
}

export type NewTutor = {
  email: string;
  password: string;
  name: string;
  authority: TutorAuthority;
};

export type NewStudent = {
  name: string;
  grade: StudentGrade;
  classSchedules: string[];
  oldSchoolCode: string;
  oldStudentNumber: string;
};

export type SchoolRegistrationResult = {
  returnedSchoolDocumentRefs: {
    schoolRef: {
      path: string;
      id: string;
    };
    configRef: {
      path: string;
      id: string;
    };
    secretRef: {
      path: string;
      id: string;
    };
  } | null;
  existingEmails: string[] | null;
  status: "success" | "email-already-exists";
};

export async function registerSchool(
  isServiceProvider: boolean,
  schoolInfo: object
): Promise<SchoolRegistrationResult> {
  const callRegisterSchool = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("register_school");

  const res = await callRegisterSchool({
    isServiceProvider,
    schoolInfo
  });

  if (
    res.data.status === "success" ||
    res.data.status === "email-already-exists"
  ) {
    return res.data;
  } else {
    throw new Error("Unexpected error occured");
  }
}

export async function updateSchool(
  schoolRef: firebase.firestore.DocumentReference,
  id: string,
  name: string
): Promise<void> {
  await schoolRef.update({ id, name });
}

export async function registerLabel(
  schoolId: string,
  label: LabelData
): Promise<{
  success: boolean;
  labelDocId: string;
  ref: firebase.firestore.DocumentReference;
}> {
  const callRegisterSchoolLabel = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("register_school_label");
  const res = await callRegisterSchoolLabel({
    schoolId,
    type: label.type,
    order: label.order,
    name: label.name
  });
  if (!res.data.success) {
    throw new Error("Unexpected error occured");
  }
  const ref = await firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(schoolId)
    .collection(labelCollectionKey)
    .doc(res.data.labelDocId);
  return {
    ...res.data,
    ref
  };
}

export async function resisterChildSchool(
  schoolConfigRef: firebase.firestore.DocumentReference,
  childSchoolDocRef: string,
  childSchoolDocId: string
): Promise<void> {
  const doc = await schoolConfigRef.get();
  const data = doc.data();
  if (!data) {
    throw new Error("コンフィグデータが見つかりません");
  }
  const childrenSchools = data.childrenSchools ?? [];
  const childrenSchoolDocIds = data.childrenSchoolDocIds ?? [];

  childrenSchools.push({ childSchoolDocRef, childSchoolDocId });
  childrenSchoolDocIds.push(childSchoolDocId);

  await schoolConfigRef.update({
    childrenSchools,
    childrenSchoolDocIds
  });
}

export async function updateParentSchoolInfoInChildrenShoolConfig(
  childScoolDocId: string,
  targetParentSchoolDocId: string,
  updates: object
) {
  const docRef = firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(childScoolDocId);

  const snapshot = await docRef.get();
  if (!snapshot) {
    return;
  }
  const data = snapshot.data();
  if (!data) {
    return;
  }
  const parentSchools: {
    schoolDocRef: string;
    schoolDocId: string;
    activateTime?: number;
    deactivateTime?: number;
  }[] = data.parentSchools;
  if (!parentSchools || parentSchools.length === 0) {
    return;
  }
  const updatedParentSchools = parentSchools.map(parentSchool => {
    if (parentSchool.schoolDocId === targetParentSchoolDocId) {
      return {
        ...parentSchool,
        ...updates
      };
    } else {
      return { ...parentSchool };
    }
  });

  await docRef.update({ parentSchools: updatedParentSchools });
}

export async function reflectUpdatedRecessToChildren(
  isServiceProviderEdited: boolean,
  parentSchoolConfigRef: firebase.firestore.DocumentReference<
    firebase.firestore.DocumentData
  >,
  recessTime: number
): Promise<void> {
  if (!isServiceProviderEdited) {
    return;
  }

  const fieldKey = "childrenSchoolDocIds";
  const snapshot = await parentSchoolConfigRef.get();
  if (!snapshot.exists) {
    return;
  }
  const data = snapshot.data();
  if (!data) {
    return;
  }
  const childrenSchoolIds: string[] = data[fieldKey];

  if (
    !childrenSchoolIds ||
    !Array.isArray(childrenSchoolIds) ||
    childrenSchoolIds.length === 0
  ) {
    return;
  }

  const parentSchoolDocId: string = parentSchoolConfigRef.id;
  const updatePromises = childrenSchoolIds.map(childSchoolId =>
    updateParentSchoolInfoInChildrenShoolConfig(
      childSchoolId,
      parentSchoolDocId,
      {
        deactivateTime: recessTime
      }
    )
  );

  await Promise.all(updatePromises);
}

export async function updateSchoolConfig(
  configRef: firebase.firestore.DocumentReference,
  paymentType: "uncontracted" | "stripe" | "external" | "none",
  recessTime: number,
  activateTime: number,
  allowCreateRoom: boolean,
  target: firebase.firestore.DocumentReference,
  zoomUserId: string,
  role: Role[],
  useSchoolAiExclusively: boolean,
  hideLoginInvitaion: boolean,
  hideMingakuStudyRoom: boolean,
  allowUseOfCustomOfficialLine: boolean,
  lineOfficialAccountChannelAccessToken: string,
  lineOfficialAccountChannelSecret: string
): Promise<void> {
  await configRef.update({
    paymentType,
    recessTime,
    activateTime,
    allowCreateRoom,
    allowUseOfCustomOfficialLine,
    target,
    zoomUserId,
    lineOfficialAccountChannelAccessToken,
    lineOfficialAccountChannelSecret,
    role,
    useSchoolAiExclusively,
    hideLoginInvitaion,
    hideMingakuStudyRoom
  });
}

export async function updateSchoolRecess(
  schoolDocId: string,
  recessTime: number
): Promise<void> {
  await firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(schoolDocId)
    .update({ recessTime });
}

export async function updateSchoolPaymentMethodToStripe(
  schoolId: string
): Promise<void> {
  await firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(schoolId)
    .update({ paymentType: "stripe" });
}

export async function deleteSchool(
  ref: firebase.firestore.DocumentReference
): Promise<void> {
  await ref.delete();
}

async function getParentShoolRefs(
  idToDelete: string
): Promise<
  firebase.firestore.DocumentReference<firebase.firestore.DocumentData>[] | null
> {
  const snapshpt = await firebase
    .firestore()
    .collection(schoolConfigCollectionKey)
    .doc(idToDelete);
  const doc = await snapshpt.get();
  if (!doc.exists) {
    console.log("削除するスクールコンフィグが取得できません");
    throw new Error("サービス事業者からスクールが削除できません");
  }
  const data = doc.data();

  if (
    !data ||
    !data.parentSchoolDocIds ||
    !Array.isArray(data.parentSchoolDocIds) ||
    data.parentSchoolDocIds.length === 0
  ) {
    return null;
  }
  const db = firebase.firestore();
  const parentSchoolIds: string[] = data.parentSchoolDocIds;
  const parentSchoolRefs = parentSchoolIds.map(parentSchoolId =>
    db.collection(schoolConfigCollectionKey).doc(parentSchoolId)
  );

  return parentSchoolRefs;
}

async function updateParentSchoolDoc(
  childIdToDelete: string,
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
) {
  try {
    const doc = await ref.get();
    const updates: Promise<void>[] = [];
    if (doc.exists) {
      const data = doc.data();
      if (data?.childrenSchools && Array.isArray(data.childrenSchools)) {
        const updatedChildrenSchools = data.childrenSchools.filter(
          item => item.childSchoolDocId !== childIdToDelete
        );
        updates.push(ref.update({ childrenSchools: updatedChildrenSchools }));
      }
      if (
        data?.childrenSchoolDocIds &&
        Array.isArray(data.childrenSchoolDocIds)
      ) {
        const updatedChildrenSchoolDocIds = data.childrenSchoolDocIds.filter(
          item => item !== childIdToDelete
        );
        updates.push(
          ref.update({
            childrenSchoolDocIds: updatedChildrenSchoolDocIds
          })
        );
      }
    }
    await Promise.all(updates);
  } catch (e) {
    alert("サービス事業者のドキュメント更新時にエラーが発生しました");
    console.error(
      "サービス事業者のドキュメント更新時にエラーが発生しました:",
      e
    );
  }
}

export async function deleteChildSchoolfromParent(
  isServiceProvider: boolean,
  ref: firebase.firestore.DocumentReference | null,
  idToDelete: string
): Promise<void> {
  let targetRefs:
    | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>[]
    | null = [];
  if (!isServiceProvider || !ref) {
    targetRefs = await getParentShoolRefs(idToDelete);
  } else {
    targetRefs.push(ref);
  }

  if (!targetRefs) {
    return;
  }

  try {
    await Promise.all(
      targetRefs.map(ref => updateParentSchoolDoc(idToDelete, ref))
    );
  } catch (e) {
    alert("サービス事業者のドキュメントから削除に失敗したスクールがあります");
    console.error(
      "サービス事業者のドキュメントから削除に失敗したスクールがあります:",
      e
    );
  }
}

export function getSchoolIdOfStudent(student: Student): string {
  if (!student) {
    return "";
  }
  const path = student.ref.path;
  const pathSegments = path.split("/");
  const schoolId = pathSegments[pathSegments.indexOf(schoolCollectionKey) + 1];
  return schoolId;
}
