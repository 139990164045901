import firebase from "firebase/app";

import {
  MessageType,
  MessageUser,
  ButtonTemplateMessage,
  MessageContent
} from "./message";
import { schoolCollectionKey } from "./school";

export const messageReservationCollectionKey = "messageReservations";

export type MessageReservation = {
  ref: firebase.firestore.DocumentReference;
  data: MessageReservationData;
};

export type MessageToType =
  | "all"
  | "group"
  | "school"
  | "classroom"
  | "students";

export type MessageTo = {
  type: MessageToType;
  group?: firebase.firestore.DocumentReference;
  school?: firebase.firestore.DocumentReference;
  classroom?: firebase.firestore.DocumentReference;
  students?: firebase.firestore.DocumentReference[];
};

export type MessageReservationData = {
  from: MessageUser;
  to: MessageTo;
  messageType: MessageType;
  messageText?: string;
  messageContents?: MessageContent[];
  reservationTime: number; // 予約時間
  templateMessage?: ButtonTemplateMessage;
  toOnlyRoomUsers?: boolean;
};

// messageReservation の [schoolDocId, reservationDocId] を返す
export function getDocIdsOfMessageReservation(
  reservation: MessageReservation
): [string, string] {
  const reservationRef = reservation.ref;
  if (
    !reservationRef.parent.parent ||
    reservationRef.parent.parent.parent.id !== schoolCollectionKey
  ) {
    return ["", reservationRef.id];
  }
  const schoolRef = reservationRef.parent.parent;
  return [schoolRef.id, reservationRef.id];
}

export function convertToMessageReservation(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): MessageReservation {
  const returnData: MessageReservationData = {
    from: data.from,
    to: data.to,
    messageType: data.messageType,
    reservationTime: data.reservationTime
  };

  if (data.messageText) {
    returnData.messageText = data.messageText;
  }

  if (data.messageContents) {
    returnData.messageContents = data.messageContents;
  }

  if (data.templateMessage) {
    returnData.templateMessage = data.templateMessage;
  }

  if (!data.toOnlyRoomUsers) {
    returnData.toOnlyRoomUsers = false;
  } else {
    returnData.toOnlyRoomUsers = data.toOnlyRoomUsers;
  }

  return {
    ref,
    data: returnData
  };
}
