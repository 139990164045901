import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", {
      for: _ctx.name,
      class: "leading-7 text-sm font-medium text-gray-500"
    }, _toDisplayString(_ctx.label), 9, ["for"]),
    _createVNode("textarea", {
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      class: "w-full bg-primary-100 rounded-md border border-primary-100 focus:border-primary-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300",
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', $event.target.value)))
    }, null, 40, ["name", "placeholder", "value"])
  ]))
}