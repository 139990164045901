import firebase from "firebase/app";

export const adminCollectionKey = "admins";

export type Admin = {
  ref: firebase.firestore.DocumentReference;
  name: string;
  isSuperAdmin?: boolean;
  prompt?: { docId: string; name: string; text: string };
};
