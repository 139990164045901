import firebase from "firebase/app";

export const tutorCollectionKey = "tutors";
export const tutorConfigCollectionKey = "tutorConfigs";

export type TutorAuthority =
  | "owner"
  | "admin"
  | "supervisor"
  | "employee"
  | "general";

export type Tutor = {
  ref: firebase.firestore.DocumentReference;
  data: TutorData;
};

export type TutorData = {
  id: string; // uid
  name: string; // スタッフ名
  school: firebase.firestore.DocumentReference; // 所属している塾への reference (security 設計上必要)
  allowEmailNotify?: boolean; // 入退室通知メールの送信をするかどうか
  allowBadgeEmailNotify?: boolean; // バッジ獲得時のメールの送信をするかどうか
  prompt?: { docId: string; name: string; text: string };
};

export type TutorConfig = {
  ref: firebase.firestore.DocumentReference;
  data: TutorConfigData;
};

export type TutorConfigData = {
  id: string; // uid
  authority: TutorAuthority; // 権限
  classrooms: firebase.firestore.DocumentReference[]; // アクセス権限のある教室一覧の reference
};

// tutor reference の [schoolDocId, tutorDocId] を返す
export function getDocIdsOfTutorRef(
  tutorRef: firebase.firestore.DocumentReference
): [string, string] {
  if (tutorRef.parent.id !== tutorCollectionKey) {
    throw new Error("given data is not tutor reference");
  }

  if (!tutorRef.parent.parent) {
    return ["", ""];
  }
  const schoolRef = tutorRef.parent.parent;
  return [schoolRef.id, tutorRef.id];
}

// tutor の [schoolDocId, tutorDocId] を返す
export function getDocIdsOfTutor(tutor: Tutor): [string, string] {
  return getDocIdsOfTutorRef(tutor.ref);
}

// tutor reference の [schoolDocId, tutorDocId] を返す
export function getDocIdsOfTutorConfigRef(
  configRef: firebase.firestore.DocumentReference
): [string, string] {
  if (configRef.parent.id !== tutorConfigCollectionKey) {
    throw new Error("given data is not tutor config reference");
  }

  if (!configRef.parent.parent) {
    return ["", ""];
  }
  const schoolRef = configRef.parent.parent;
  return [schoolRef.id, configRef.id];
}

export function getSchoolRefFromTutorRef(
  tutorRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (tutorRef.parent.id !== tutorCollectionKey) {
    throw new Error("given data is not tutor reference");
  }
  return tutorRef.parent.parent!;
}

export function convertToTutor(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Tutor {
  const returnData: TutorData = {
    id: data.id,
    name: data.name,
    school: data.school
  };
  if (data.allowEmailNotify) {
    returnData.allowEmailNotify = data.allowEmailNotify;
  }
  if (data.allowBadgeEmailNotify) {
    returnData.allowBadgeEmailNotify = data.allowBadgeEmailNotify;
  }
  if (data.selectedPrompt) {
    returnData.prompt = data.selectedPrompt;
  }

  return {
    ref,
    data: returnData
  };
}

export function convertToTutorConfig(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): TutorConfig {
  const returnData: TutorConfigData = {
    id: data.id,
    authority: data.authority,
    classrooms: data.classrooms
  };

  return {
    ref,
    data: returnData
  };
}
